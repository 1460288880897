<template>
  <div class="my-header">
    <el-form label-width="80px" :model="query" size="small">
      <el-row>
        <template v-for="{ label, prop, placeholder } in Files">
          <el-col v-if="prop === 'dateRange'" :span="12" :key="prop">
            <el-form-item :label="label">
              <div style="display: inline-block">
                <SaleDateTime
                  ref="saleDateTime"
                  class="sale-date-time-wrapper"
                  :time.sync="time"
                  @change="tickToQuery"
                  :howDate="howDate"
                ></SaleDateTime>
              </div>
            </el-form-item>
          </el-col>
          <el-col v-else-if="prop === 'categoryName'" :key="prop" :span="6">
            <el-form-item :label="label" style="margin-bottom: 19px">
              <MyCascader
                :options="productOptions"
                :value.sync="choiceType"
                :propsItem="propsItem"
                :placeholder="placeholder"
              ></MyCascader>
            </el-form-item>
          </el-col>

          <el-col v-else-if="prop === 'orderType'" :key="prop" :span="6">
            <el-form-item :label="label">
              <SelectList
                :placeholder="placeholder"
                :keyword.sync="inputVar[prop]"
                :menus="orderTypeList"
                :keyObj="{ label: 'label', value: 'value' }"
                @change="inputChange"
              />
            </el-form-item>
          </el-col>

          <el-col v-else-if="prop === 'orderType1'" :key="prop" :span="6">
            <el-form-item :label="label">
              <SelectList
                :placeholder="placeholder"
                :keyword.sync="query['orderType']"
                :menus="orderTypeList"
                :keyObj="{ label: 'label', value: 'value' }"
                @change="tickToQuery"
              />
            </el-form-item>
          </el-col>

          <el-col v-else :key="prop" :span="6">
            <el-form-item :label="label">
              <el-input
                v-model="inputVar[prop]"
                suffix-icon="el-icon-search"
                :placeholder="placeholder"
                clearable
                @change="inputChange"
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import SaleDateTime from '@/components/saleDateTime'
import { deepClone } from '@/components/avue/utils/util'
import { commonEheaderMixin } from '@/mixins'
import MyCascader from '../MyCascader'
import { mapGetters } from 'vuex'
import { parseCharacterTime } from '@/utils'

export default {
  mixins: [commonEheaderMixin],
  components: { SaleDateTime, MyCascader },
  props: {
    Files: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      default: ''
    },
    categoryOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    howDate: {
      type: Array,
      default: () => {
        return [
          {
            label: '今日',
            value: '1'
          },
          {
            label: '昨日',
            value: '2'
          }
        ]
      }
    }
  },
  data() {
    //const time = parseCharacterTime(new Date()).split(' ')[0]
    return {
      time: ['', ''],
      formField: [
        {
          prop: 'createTime'
        }
      ],
      choiceType: [],
      inputVar: {},
      propsItem: {
        // 次级展开方式 click / hover
        expandTrigger: 'hover',
        // 是否能选择末尾节点外的节点
        checkStrictly: true,
        // 指定options里对象的某个属性值为选项的值 例如
        value: 'id',
        // 指定选项标签为选项对象的某个属性值
        label: 'name'
      }
    }
  },
  computed: {
    ...mapGetters(['orderTypeList']),

    productOptions() {
      const productOptions = deepClone(this.categoryOptions)
      productOptions.map((item) => {
        if (!item.children.length) {
          delete item.children
        } else {
          item.children.map((citem) => {
            if (!citem.children.length) {
              delete citem.children
            }
          })
        }
      })
      return productOptions
    }
  },
  watch: {
    choiceType: {
      handler(newVal) {
        !this.tabChangeFlag && this.$emit('categoryChange', newVal[newVal.length - 1])
      },
      deep: true
    },
    activeName: {
      handler() {
        this.tabChangeFlag = true
        this.$nextTick(() => (this.tabChangeFlag = false))

        const { saleDateTime } = this.$refs
        if (saleDateTime) {
          saleDateTime.map((dom) => {
            dom.value = []
            this.time = dom.labelChange((dom.label = '1'))
          })
        }
        this.inputVar = {}
        this.choiceType = []
        this.handleSearchForm()
      }
    }
  },
  methods: {
    tickToQuery() {
      !this.tabChangeFlag &&
        this.$nextTick(() => {
          this.toQuery()
        })
    },
    inputChange() {
      this.$nextTick(() => {
        this.$emit('headerChange', this.inputVar)
      })
    }
  },
  created() {}
}
</script>

<style scoped lang="scss">
.my-header {
  padding: 20px 20px 0 20px;
  background-color: #f7f8fa;
  overflow: hidden;

  ::v-deep .el-form--inline {
    overflow: hidden;
  }
  ::v-deep .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 20px;
  }
}
</style>
